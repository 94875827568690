import {Box, Button, Center, createStyles, List, Loader, ScrollArea, Text} from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { WidgetCard } from 'src/components/WidgetCard/WidgetCard';
import {selectActiveWidget, selectSelectedWidgets } from 'src/components/Widget/selectors';
import { WidgetsHeader } from 'src/components/Widget/Header';
import { WidgetList } from 'src/components/Widget/WidgetList';
import { ConfirmDeletionModal } from 'src/components/Library/ConfirmDeletionModal';
import { useWidgetsList } from 'src/shared/hooks/useWidgetsList';
import { WidgetLayout } from 'src/components/Widget/WidgetLayout';
import { useFilterWidgetsList } from 'src/shared/hooks/useFilterWidgetsList';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { widgetsActions } from 'src/components/Widget/store';
import {ActiveWidget, WidgetStatus} from 'src/components/Widget/types';
import {useState, useEffect, useRef} from 'react';
import { videoListActions } from 'src/components/Library/store';
import { getFilterFromSessionStorage } from 'src/shared/helpers/sessionStorage';
import { WIDGETS_FILTER_KEY } from 'src/shared/consts';
import {useGetWidgetsManger} from "../../shared/hooks/tanstackQuery/useGetWidgetsManger";
import {usePutWidget} from "../../shared/hooks/tanstackQuery/usePutWidget";
import {checkAndRefetchOnboardingData} from "../../api/onboarding/checkAndRefetchOnboardingData";
import {useFeatureRestriction} from "../../shared/hooks/useFeatureRestriction";
import {notifications} from "@mantine/notifications";
import {IconCheck} from "@tabler/icons-react";
import {useCheckWidgetBlockStatuses} from "../../shared/hooks/useCheckWidgetBlockStatuses";
import {useCheckInstallation} from "../../shared/hooks/useCheckInstallation";
import {checkAppBlock} from "../../api/widget/checkAppBlock";
import {sendLivePreviewUsed} from "../../shared/eventLogger/eventLogger";
import {countOfWidgetBlockStatusesErrors} from "../../shared/services/widgetBlockStatusesToText";
import {WidgetCardButtonError} from "./popups/WidgetCardButtonError";



const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  widgetListWrapper: {
    padding: '35px 7px 35px 87px',
  },
  scrollbar: {
    backgroundColor: 'transparent',
    padding: 2,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&[data-orientation="vertical"]': {
      width: '8px',
      '.mantine-ScrollArea-thumb': {
        borderRadius: '100px',
        backgroundColor: 'rgba(193, 194, 197, 0.20)',
      },
    },
  },
  notFound__container: {},
  notFound__text: {
    color: theme.colors.greyLight[2],
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  notFound__resetButton: {
    width: '150px',
    color: theme.colors.greyLight[2],
    '&:hover': {
      backgroundColor: 'rgba(23, 232, 133, 0.1)',
    },
  },
}));

export const Widgets = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const stringSearch = searchParams.toString();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const filterFromSessionStorage = getFilterFromSessionStorage(WIDGETS_FILTER_KEY);

    if (filterFromSessionStorage) {
      setSearchParams(filterFromSessionStorage);
    }

    setIsReady(true);
  }, [stringSearch])

 const {
    mutate: checkWidgetBlockStatuses,
 } = useCheckWidgetBlockStatuses()

  useEffect(() => {
    checkWidgetBlockStatuses()
  }, [])
  if (!isReady) {
    return null
  }

  return (
    <WidgetsInner />
  );
}

const WidgetsInner = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedWidgets = useSelector(selectSelectedWidgets);

  const { filter, filterHandler, filteredWidgets, resetFilter, editGetWidgets, isLoading } = useFilterWidgetsList();

  const {
    onChecked,
    onDelete,
    onDuplicate,
    onRename,
    confirmDelete,
    closeDeletionModal,
    deletionModalState,
    isWidgetsLoading,
  } = useWidgetsList({
    onEdit: editGetWidgets,
    onSuccess: (numOfWidgets) => {
      if (numOfWidgets < 1) {
        navigate('/widgets/new', { replace: true });
      }
    },
  });
  const editWidget = (widget: ActiveWidget, options?: {
    tab?: 'installation'
  }) => {
    dispatch(widgetsActions.setActiveWidget(widget));
    if(options?.tab === 'installation') {
      navigate(`/widgets/edit/${widget.id}?defaultTab=installation`, { replace: true });
    } else {
      navigate(`/widgets/edit/${widget.id}`, {replace: true});
    }
  };
  const {refetch} = useGetWidgetsManger();

  const {mutate: putWidget} = usePutWidget({
    onSuccess: () => {
      refetch();
      checkAndRefetchOnboardingData();
    }
  })
  const restrictions = useFeatureRestriction()
  const widgetsRef = useRef(filteredWidgets)
  useEffect(() => {
    widgetsRef.current = filteredWidgets
  }, [filteredWidgets]);
  const {mutate: checkInstallation, data: appInstalationStatus, mutateAsync: checkInstallationAsync} = useCheckInstallation()
  useEffect(() => {
    checkInstallation()
  }, []);
  const changeWidgetStatus =  async (widgetId: string, widgetStatus: WidgetStatus) => {
    if(widgetStatus === WidgetStatus.published){

      if(restrictions.isLoading) return;
      restrictions.onPublishWidget( async ()=> {
        const [appBlock, widgetBlock] = await Promise.all([
          checkInstallationAsync(),
          checkAppBlock(widgetId)
        ])
        const widget = filteredWidgets.find((w) => w.id === widgetId)
        const widgetBlockStatuses = widgetBlock.data?.blockStatuses ?? []
        const title = widget?.name ?? 'Widget'

        const warnings = countOfWidgetBlockStatusesErrors(widgetBlockStatuses, appBlock?.status === 'OK')
        const cantPublish =  warnings > 0
        if (cantPublish) {
          if (widget) {
            editWidget(widget, {
              tab: 'installation'
            })
          }
          notifications.show({
            title: `Alerts for ${title} widget`,
            message: <WidgetCardButtonError widgetTitle={widget?.name || ''} appEmbedDisabled={appBlock?.status !== 'OK'} widgetBlockStatuses={widgetBlockStatuses} />,
            color: 'red.0',
            styles: (theme) => ({
              root: {
                backgroundColor: theme.colors.greyDark[5]
              },
              title: {color: theme.colors.whitePrimary},
            })

          })

        } else {
          putWidget({
            id: widgetId,
            status: widgetStatus,
          })
          notifications.show({
            autoClose: 1000 * 11,
            title: `${title} has been published`,
            message: 'Now visitors can see it on the website',
            color: 'green.1',
            icon: <IconCheck />,
            styles: (theme) => ({
              root: {
                backgroundColor: theme.colors.greyDark[5]
              },
              title: { color: theme.colors.whitePrimary },
            })
          })
        }
      })
      return;
    }
    putWidget({
      id: widgetId,
      status: widgetStatus,
    })
  }

  const onPreview = async (widgetId: string) => {
    const [appBlock, widgetBlock] = await Promise.all([
      checkInstallationAsync(),
      checkAppBlock(widgetId)
    ])
    const {data} = widgetBlock;
    const errors = countOfWidgetBlockStatusesErrors(data.blockStatuses, appBlock.status === 'OK')
    const widget = widgetsRef.current.find((w) => w.id === widgetId)

    if(errors === 0) {
      sendLivePreviewUsed()
      window.open(data.preview_url, '_blank');
    }

    if(errors > 0) {
      if(widget) {
        editWidget(widget, {
          tab: 'installation'
        })
      }
      notifications.show({
        autoClose: 1000 * 11,
        title: `Alerts for ${widget?.name} widget`,
        message: <WidgetCardButtonError widgetTitle={widget?.name || ''} appEmbedDisabled={appBlock.status !== 'OK'} widgetBlockStatuses={data.blockStatuses} />,
        color: 'red.0',
        styles: (theme) => ({
          root: {
            backgroundColor: theme.colors.greyDark[5]
          },
          title: {color: theme.colors.whitePrimary},
        })

      })
    }
  }

  if (isWidgetsLoading) {
    return (<Center h="100%">
      <Loader size="lg"/>
    </Center>)
  }


  return (
    <Box className={classes.container}>
      <WidgetLayout filter={filter} onChange={filterHandler} />
      <Box className={classes.main}>

        <WidgetsHeader
          filteredWidgets={filteredWidgets}
        />

        <ScrollArea
          h={'calc(100vh - 60px)'}
          type="auto"
          classNames={{
            scrollbar: classes.scrollbar,
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 60px)',
              }}
            >
              <Loader size="xl" />
            </Box>
          ) : (
            <Box className={classes.widgetListWrapper}>
              <WidgetList>
                {filteredWidgets.length < 1 && (
                  <Box className={classes.notFound__container}>
                    <Text className={classes.notFound__text}>No Widgets found</Text>
                    <Button
                      className={classes.notFound__resetButton}
                      variant="subtle"
                      onClick={resetFilter}
                    >
                      Reset the filter
                    </Button>
                  </Box>
                )}
                {filteredWidgets.map((w) => (
                  <WidgetCard
                    key={w.id}
                    id={w.id}
                    title={w.name ?? 'Widget'}
                    checked={selectedWidgets.includes(w.id)}
                    onChecked={() => onChecked(w.id)}
                    onDuplicate={() => onDuplicate(w.id)}
                    onRename={(newName) => onRename(w.id, newName)}
                    onDelete={() => onDelete(w.id)}
                    status={w.status}
                    isCreatedManually={w.type === 'Manual'}
                    type={w?.settings?.general?.widgetViewType}
                    page={w.page}
                    editWidget={() => editWidget(w)}
                    videos={w.videos}
                    allPages={w.allPages}
                    onUnpublish={() => changeWidgetStatus(w.id, WidgetStatus.draft)}
                    onPublish={() => changeWidgetStatus(w.id, WidgetStatus.published)}
                    blockStatuses={w.blockStatuses}
                    onPreview={() => onPreview(w.id)}
                  />
                ))}
              </WidgetList>
            </Box>
          )}
        </ScrollArea>
        <ConfirmDeletionModal
          onCancel={closeDeletionModal}
          onConfirm={confirmDelete}
          open={deletionModalState}
          onClose={closeDeletionModal}
          bodyText="Are you sure you want to remove widget?"
          title="Remove widget"
        />
      </Box>
    </Box>
  );
};
